import React from "react";
import arrowdown from "../../assets/icons/Caretdown.png";
import './_DropDown.scss'
const DropDown = () => {

  return (
    <div className="dropDown__img">
      <img src={arrowdown} alt="arrowdown" />
    </div>
  );
};

export default DropDown;
